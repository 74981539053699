<template lang="pug">
  .cm-body.course-day
    slot
    .course-day__header(v-if="activeCourseDay")
      .course-day-title {{ courseName }}
      transition(name="cardX")
        candidates-exams-stats(v-if="candidatesExamCount" :countList="candidatesExamCount")

    .cm-body__wrapper
      filters(@filterChanged="change")

      .course-day__info(v-if="activeCourseDay && activeItem && getActiveCourseLeaders && (getActiveCourseLeaders[0].leader || activeItem.meeting_space)")
        .course-day__info-meeting-space(v-if="activeItem && activeItem.meeting_space")
          p(v-if="activeItem.meeting_space").text {{ activeItem.meeting_space.name }}

        .course-day__info-leaders(v-if="activeItem && getActiveCourseLeaders && getActiveCourseLeaders[0].leader")
          p.text(v-if="item.session && item.leader" v-for="(item, index) in getActiveCourseLeaders" :key="index") {{ item.session.id }} - {{ item.leader.name }}
            template(v-if="!(index === getActiveCourseLeaders.length - 1)") ,&nbsp;

      actions(
        :activeItem="activeItem"
        :activeItems="activeItems"
        :groupedList="groupedList"
        :list="list"
        :loading="loading"
        @update="updateCourseList"
      )
      .course-day__additional
        tabs(:counts="candidatesCount" :disabled="!activeCourseDay" @changed="onTabsChange")
          template(v-slot:tabs-slot)
            .select-items(v-if="hasActiveItems")
              span.count {{ getActiveItemsLength }}
              span.text items selected
              span(v-if="showSelectAll") |
              selectAll(
                v-if="showSelectAll"
                @change="selectAllClick"
                :activeItemsCount="getActiveItemsLength"
                :itemsToSelectCount="pagination.count"
              ).ml-1.mr-2

            .cm-body__pagination.pagination__container
              pagination-widget(v-if="pagination" :pagination="pagination" :loading="loading" @change="change")

        .checklist(v-if="activeCourseDay")
          course-checklist(:activeItem="activeItem" :courseName="courseName")

      grouped-table(
        v-model="activeItems"
        :headers="headers"
        :loading="loading"
        :class="{'hide-zoom': !featureFlags[FEATURE_FLAG.NCRM_3360], 'cl-access': isCourseLeadersAccess, 'select-course-day': activeCourseDay}"
        :groupedList="groupedList"
        :disabled="!activeCourseDay"
        :list="list"
        :sort-by.sync="sortModel.by"
        :sort-desc.sync="sortModel.desc"
        @update:sort-desc="updateSorting"
        itemKey="ID"
        grouped
        class="app-table cm-body__table v-data-table theme--light table-courseDayBody"
      )
        template(v-slot:header="headerRow")
          header-row(:headerRow="headerRow")
        template(v-slot:group="{group}")
          .journey-list__item
            span.group-label {{ group.label }}
            courseDaySessionsList(:sessionsList="group.sections")
            div.ml-auto.mr-2.pointer
              journey-crud(
                :key="group.journeyDayID"
                :journeyItemID="group.journeyDayID"
                @click:remove="$emit('needToUpdate', true)"
                @click:create="$emit('needToUpdate', true)"
                @click:update="$emit('needToUpdate', true)"
                editable
              )
                template(v-slot:activator="createJourney")
                  span(@click="createJourney.open").d-flex.text--white
                    ico-dots-horizontal
        template(v-slot:item="{item, select, isSelected, index, expand, isExpanded}")
          courseRow(
            v-if="!isCourseLeadersAccess"
            :item="item"
            :index="index"
            :key="item.ID"
            :isSelected="isSelected"
            :select="select"
            :expand="expand"
            :isExpanded="isExpanded"
            :activeItems="activeItems"
            :primaryPagination="primaryPagination"
            :labels="labels"
            :course-date="courseDate"
            @click:payments="paymentsOpen"
            @onGoToEdit="goToEdit"
            @selectedWithShiftkey="extendedGroupSelection"
            @onWillComeUpdate="$emit('onRemoveFromGroup', $event)"
            @redirected="onTabsChange"
          )
          courseLeadersRow(
            v-if="isCourseLeadersAccess"
            :item="item"
            :index="index"
            :key="item.ID"
            :isSelected="isSelected"
            :select="select"
            :expand="expand"
            :isExpanded="isExpanded"
            :activeItems="activeItems"
            :primaryPagination="primaryPagination"
            :labels="labels"
            :course-date="courseDate"
            @click:payments="paymentsOpen"
            @onGoToEdit="goToEdit"
            @selectedWithShiftkey="extendedGroupSelection"
            @onWillComeUpdate="$emit('onRemoveFromGroup', $event)"
            @redirected="onTabsChange"
          )
        template(v-slot:expanded-item="{ headers, item, expand }")
          td(:colspan="headers.length").pl-0.pr-0.app-table__expand
            candidate-item(
              ref="expand"
              :ID="item.ID"
              :key="item.ID"
              @close="hideExpandBy(expand)"
              @saved="updateTableDataBy(item, $event)"
              @needToUpdate="$emit('needToUpdate', true)"
            ).pb-12

        template(v-slot:footer="footerOptions")
          slot(name="footer" :footerOptions="footerOptions")

      paymentsWrapper(ref="payments" :list="list")
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import SortModel from '@/app/admin/modules/candidates/core/models/candidatesSortModel';
import expandMixin from '@/app/admin/mixins/expand.mixin';
import showingMixin from '@/mixins/showing.mixin'
import AutocompleteRepo from '@/app/admin/modules/candidates/core/models/candidateAutocompleteRepo'
import Repo from '@/services/api/util/auth-repo-local-storage'
import CmBodyMixin from '../course_managment/components/curssusen/cmBody.mixin'
import { DDMMYYYYFormatting } from '@/util'
import CourseDayModel from '@/app/admin/modules/course_day/core/courseDay.model'
import { COURSE_DAY_HEADERS } from '@/app/admin/modules/course_day/core/courseDay-const'
import featureFlagsMixin from '@/mixins/featureFlags.mixin'
import permsMixin from '@/mixins/perms.mixin'

export default {
  name: 'CourseDayBody',

  inject: ['setQuery', 'candidatesActionsSvc'],

  mixins: [expandMixin, permsMixin, showingMixin, CmBodyMixin, featureFlagsMixin],

  props: {
    list: Array,
    loading: Boolean,
    candidatesCount: Object,
    primaryPagination: Object,
    courses: Object,
    pagination: Object,
    groupedList: null,
    activeItem: Object,
    candidatesExamCount: Object
  },

  data: () => ({
    sortModel: null,
    sortBy: [],
    sorting: [],
    activeItems: [],
    autocompleteRepo: new AutocompleteRepo(Repo),
    headers: COURSE_DAY_HEADERS
  }),

  created() {
    this.autocompleteRepo.loadDataByFields()
    this.initSortModel()
  },

  mounted() {
    this.loadLabels({cache: true})
  },

  computed: {
    ...mapGetters({
      labels: 'labels/items',
      cities: 'crmCourseCities/items',
      activeCourses: 'cm/activeCourses'
    }),

    courseName() {
      if (!this.activeItem) return ''
      let city = this.cities.find(city => city.ID === this.activeItem.city)
      return `${city.name} (${DDMMYYYYFormatting(this.activeItem.date)}) ${this.activeItem.name}`
    },

    courseDate() {
      if (!this.activeItem) return ''
      return this.activeItem.date
    },

    getActiveCourseLeaders() {
      if (!this.activeItem || !this.activeItem.course_day_sections) return []

      let _courseDayInfo = this.activeItem.course_day_sections.map(section => {
        return {leader: section.leader, session: section.session}
      })

      if (!_courseDayInfo) return []
      else return _courseDayInfo.sort((a, b) => a.session.id - b.session.id)
    },

    activeCourseDay() {
      return +this.$route.query.course_day
    },

    hasActiveItems() {
      return !!(this.activeItems && this.activeItems.length)
    },

    getActiveItemsLength() {
      if (!this.activeItems) return ''
      else return this.activeItems.length
    }
  },

  methods: {
    ...mapActions({
      loadLabels: 'labels/list',
      courseListDetailed: 'cm/courseListDetailed',
    }),

    initSortModel() {
      this.sortModel = new SortModel(this.$route.query)
    },

    async updateSorting() {
      let data = this.sortModel.getApiData(this.$route.query)
      let ordering
      if (data.ordering.length) ordering = data.ordering
      await this.setQuery({ordering: ordering})
      this.change()
    },

    paymentsOpen(data) {
      this.$refs.payments.paymentsOpen(data)
    },

    async updateCourseList() {
      await this.courseListDetailed({...this.$route.query, size: undefined})
    },

    extendedGroupSelection({item, index}) {
      let selectedGroup
      for (let group in this.groupedList) {
        let selectedItem = this.groupedList[group].items.find(_item => {
          return _item.ID === item.ID
        })
        if (selectedItem) selectedGroup = group
      }
      if (!selectedGroup) return
      this.extendedSelection({item, index}, this.groupedList[selectedGroup].items)
    },

    change() {
      this.$emit('change')
    },

    updateTableDataBy(item, candidate) {
      candidate.exam = {
        ...candidate.exam,
        transport_type: item.exam.transport_type,
        journey_was_changed: item.exam.journey_was_changed
      }
      item.merge(candidate)
    },
  },

  provide: function () {
    return {
      autocompleteRepo: this.autocompleteRepo,
      svc: () => this.svc,
      candidatesActionsSvc: () => this.candidatesActionsSvc,
      CandidateModel: CourseDayModel
    }
  },

  components: {
    actions: () => import('@/app/admin/modules/course_day/components/CourseDayActions.vue'),
    tabs: () => import('./components/CourseDayTabs.vue'),
    filters: () => import('./components/CourseDayFilters.vue'),
    selectAll: () => import('@/app/admin/modules/candidates/components/CandidateSelectAll.vue'),
    headerRow: () => import('../course_managment/components/CMHeaders.vue'),
    groupedTable: () => import('@/components/global/GroupedTable.vue'),
    courseRow: () => import('./components/CourseDayRow.vue'),
    courseLeadersRow: () => import('./components/courseLeadersView/CourseDayLeadersRow.vue'),
    courseDaySessionsList: () => import('./components/body/courseDaySessionsList.vue'),
    candidateItem: () => import('@/app/admin/modules/candidates/components/item/CandidateItem.vue'),
    journeyCrud: () => import('@/app/admin/modules/course_day/components/journey/JourneyCRUD.vue'),
    icoDotsHorizontal: () => import('@/assets/img/ui/components/IcoDotsHorizontal.vue'),
    paginationWidget: () => import('@/components/widget/PaginationWidget.vue'),
    candidatesExamsStats: () => import('@/app/admin/modules/course_day/components/body/CandidatesExamsStats.vue'),
    courseChecklist: () => import('@/app/admin/modules/course_day/components/body/CourseChecklist.vue'),
    paymentsWrapper: () => import('@/app/admin/modules/candidates/components/payments/CandidatePaymentWrapper.vue')
  }
}
</script>

<style lang="scss" scoped>
.cm-body {
  display: flex;
  overflow: hidden;
  flex-direction: column;
  width: 100%;
  height: 100%;

  &__table {
    position: relative;
    overflow: auto;
    height: 100%;
    box-shadow: unset;

    @extend %visible-scrollbar;
  }

  &__wrapper {
    display: flex;
    overflow: hidden;
    flex-direction: column;
    width: 100%;

    &::v-deep {
      .v-data-table__wrapper {
        overflow: scroll;
        padding-bottom: 10px;

        > table {
          width: auto;
        }
      }
    }
  }

  &__pagination {
    display: flex;
    align-items: center;
    justify-content: center;

    &.pagination__container {
      margin-left: auto;

      @include media("<=phone") {
        margin-right: auto;
        margin-left: initial;
      }
    }
  }
}

.course-day {
  display: flex;
  justify-content: flex-start;

  &__ {
    &header {
      display: flex;
      align-items: center;
      flex-direction: row;
      justify-content: space-between;
      padding: 11px;
      border-bottom: 1px solid $border-color;

      @include media("<=tablet") {
        padding: 5px 10px;
      }
    }

    &block {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      background-color: $white;
    }
    &info {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      padding: 10px;
      border-bottom: 1px solid $border-color;

      &-leaders {
        display: block;
      }
    }

    &additional {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      border-bottom: 1px solid #EAEBF1;

      @include media("<=phone") {
        flex-direction: column;
      }

      .checklist {
        @include media("<=phone") {
          position: absolute;
          top: 0;
          right: -10px;
        }
      }
    }

    &actions {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      padding: 10px;
      border-bottom: 1px solid $border-color;

      @include media("<=tablet") {
        display: none;
        flex-direction: column;
        width: 100%;
      }

      &.opened {
        display: flex;
      }

      &__mobile {
        display: none;

        @include media("<=tablet") {
          display: flex;
        }
      }
    }
  }

  .course-day-title {
    overflow: hidden;
    margin-right: 10px;
    color: #232428;
    text-overflow: ellipsis;
    //
    white-space: nowrap;
    word-break: break-all;
    letter-spacing: normal;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    font-size: 14px;
    font-family: $font-roboto;
    line-height: normal;

    @include media("<=phone") {
      font-size: 12px;
    }
  }
}

.cl-access.app-table::v-deep table {
  min-width: auto !important;
}

.app-table::v-deep table {
  min-width: $table-min-width;
  table-layout: fixed;

  @include media("<=tablet") {
    min-width: $mobile-min-width;
  }

  & th:first-child {
    width: 30px !important;
  }

  & th:last-child,
  td:last-child {
    padding-right: 8px;
  }

  .cell--btns {
    width: 40px;
  }

  .cell {
    &--name {
      width: 127px;

      @include media("<=tablet") {
        min-width: 40px;
        max-width: 40px;
        width: 40px;
      }
    }

    &--code {
      width: 80px;

      @include media("<=tablet") {
        width: 50px;
      }
    }

    &--index {
      width: 36px;

      @include media("<=tablet") {
        width: 21px;
      }
    }

    &--color {
      @include media("<=tablet") {
        max-width: 32px;
        width: 32px;
        transform: scale(0.8);
      }
    }

    &--cbr {
      width: 70px;
    }

    &--reserved {
      width: 105px;

      @include media("<=tablet") {
        min-width: 50px;
        max-width: 50px;
        width: 50px;
      }
    }

    &--bday {
      width: 95px;

      @include media("<=tablet") {
        width: 75px;
      }
    }

    &--result {
      text-align: center;

      @include media("<=tablet") {
        width: 58px;
      }
    }

    &--hold {
      width: 68px;
    }

    &--status {
      width: 61px;
      text-align: center;
    }

    &--send-results {
      text-align: center;

      @include media("<=tablet") {
        width: 37px;
      }
    }

    &--afezig {
      text-align: center;

      @include media("<=tablet") {
        width: 43px;
      }
    }

    &--delete {
      width: 25px;
    }

    &--error {
      width: 100px;
    }

    &--email {
      width: 28px;
    }

    &--dates {
      width: 120px;
    }

    &--placed-paid {
      width: 90px;
    }

    &--reservation {
      width: 150px;
    }

    &--product {
      width: 80px;
      text-align: center;

      @include media("<=tablet") {
        min-width: 35px;
        max-width: 35px;
        width: 35px;
      }
    }

    &--email {
      width: 28px;
    }

    &--number {
      width: 100px;

      @include media("<=tablet") {
        width: 50px;
      }
    }

    &--zoom-status {
      width: 75px;
    }

    &--course-type {
      width: 80px;
    }

    &--note {
      min-width: 50px;
      width: 80px;
      text-align: center;

      @include media("<=tablet") {
        min-width: 90px;
        width: 90px;
      }
    }

    &--payment {
      width: 70px;
    }

    &--presentie {
      text-align: center;

      @include media("<=tablet") {
        max-width: 40px;
        width: 40px;

        overflow-wrap: break-word;
      }
    }

    &--wiil_come {
      text-align: center;

      @include media("<=tablet") {
        width: 37px;
      }
    }

    &--appid {
      width: 70px;
      text-align: center;

      @include media("<=tablet") {
        width: 37px;
      }
    }

    &--type {
      @include media("<=tablet") {
        width: 40px;
      }
    }
  }
}

.select-items {
  display: flex;
  align-items: center;
  flex-direction: row;
  padding: 0 10px;
  border-left: 1px solid $border-color;

  @include media("<=phone") {
    width: 100%;
    border-top: 1px solid $border-color;
    border-left: none;
  }

  .text {
    margin-right: 5px;
    color: $primary-color;
    font-size: 14px;
  }

  .count {
    margin-right: 5px;
    color: $primary-color;
    font-weight: 600;
  }
}

.actions__wrapper {
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-right: 8px;
  margin-bottom: 4px;

  @include media("<=tablet") {
    flex-direction: column;
    margin-right: 0;
  }

  &.double {
    align-items: center;
    flex-direction: row;
    justify-content: space-around;

    @include media("<=tablet") {
      padding-top: 10px;
    }
  }
}

</style>

<style lang="scss">
.table-courseDayBody {
  overflow-x: hidden !important;
  height: 100vh;

  .group-label {
    display: flex;
    align-items: center;
    padding-left: 16px;
    color: white;
    font-size: 17px;
  }

  .journey-list {
    &__item {
      display: flex;
      align-items: center;
      flex-direction: row;
      justify-content: flex-start;
      padding-top: 4px;
      height: 50px;
    }
  }

  &::v-deep table {
    min-width: 1250px;
  }

  .v-chip__content {
    color: #FFFFFF;
    text-align: center;
    letter-spacing: normal;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    font-size: 12px;
    font-family: $font-roboto;
    line-height: normal;
  }

  thead {
    th {
      padding-top: 0 !important;
      padding-bottom: 0 !important;

      span {
        @include media("<=phone") {
          font-size: 10px !important;
        }
      }
    }
  }
}

// feature flags
.hide-zoom {
  .cell--zoom-status {
    display: none;
  }
}

// Course Leaders Access
.cl-access {
  .cell--select,
  .cell--btns,
  .cell--number,
  .cell--bday,
  .cell--cbr,
  .cell--payment,
  .cell--email,
  .cell--zoom-status,
  .cell--app-note,
  .cell--transport-config {
    display: none;
  }
}

// action item (buttons)
.action-item {
  display: flex;
  flex-direction: column;
  margin-right: 8px;
  margin-bottom: 4px;

  @include media("<=tablet") {
    margin-right: 0;
    width: 100%;
    .v-btn {
      width: 100%;
    }
  }
}

</style>
